<template>

  <div class="table-self">
    <div class="tb-title" v-if="title">
      <span v-if='!changeRowBgColor'>{{ title }}</span>
      <slot v-else name='title'/>
    </div>

    <table border="0">
      <thead>
        <tr>
          <th v-for="item in columns" :key="item.key">{{ item.title }}</th>
        </tr>
      </thead>

      <tbody :class="isZebra ? 'zabra' : ''">
        <tr v-for="(item, index) in dataSource" :key="index" class="tb-item" :style="{background: changeRowBgColor ? changeRowBgColor(item) : ''}">
          <td v-for="v in columns" :key="v.key">
            <!-- 使用slot是因为渲染的元素有事件 -->
            <slot :name="v.key" :="{ item, index }" v-if="v.slot" />
            <span v-else>{{
              (v.render && v.render(item[v.key], v, item)) || item[v.key]
            }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    columns: [],
    dataSource: [],
    title: null, //标题
    isZebra: {
      type: Boolean,
      default: false,
    }, //表格body内容背景斑马纹展示
    changeRowBgColor: {
      type: null,
      default: null
    }  //自定义单元行背景颜色
  },
  setup() {
    const handleClick = () => {
      console.log(1231231);
    };

    return {
      handleClick,
    };
  },
});
</script>

<style scoped lang="scss">
.table-self {
  width: 100%;
  font-size: 0.11rem;
  line-height: 0.3rem;
  overflow: hidden;
  .tb-title {
    background: #f7f7f7;
    border-bottom: 1px solid #ebedf0;
  }
  table {
    border-collapse: collapse;
    text-align: center;
    padding: 0 0.04rem;
    &,
    thead,
    tbody {
      min-width: 100%;
      overflow: scroll;
    }
    thead {
      background: #f7f7f7;
      font-size: 0.11rem;
    }
    tbody {
      &.zabra {
        tr:nth-of-type(2n) {
          background: #f7f7f7;
        }
      }
      td {
        padding: 0.05rem 0.1rem;
      }
      .tb-item {
        border-bottom: 1px solid #f7f7f7;
      }
    }
  }
}
</style>
