<template>
  <div class="household">
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="filter.building"
        :title="filter.building + '号楼'"
        :options="buildingNums"
        @change="changeBuild"
      />
      <van-dropdown-item
        v-model="filter.unit"
        :title="filter.unit + '单元'"
        :options="unitNums"
        @change="getList"
      />
    </van-dropdown-menu>

    <Table
      :columns="columns"
      :dataSource="tableData"
      :title="filter.unit + '单元'"
      :changeRowBgColor='changeRowBgColor'
    >
      <template v-slot:title>
        <div>
          {{filter.unit + '单元'}}
          <div class='custom_title'>
            <div><span/>住户报停</div>
            <div><span class='temp_high'/>数据异常</div>
            <div><span class='empty'/>设备异常</div>
          </div>
        </div>
      </template>
      <template v-slot:index="scope">
        <div @click="() => handleClick(scope)">▾</div>
      </template>
      <template v-slot:deviceStatus="scope">
        <div>{{filterStatus(scope)}}</div>
      </template>
    </Table>
  </div>
</template>

<script>
import Table from "../components/Table.vue";
import { useRouter } from "vue-router";
import { getCurrentInstance, onMounted, ref } from "vue";
// import { Toast } from "vant";

export default {
  components: {
    Table,
  },
  setup(_, context) {
    const router = useRouter();

    const { proxy } = getCurrentInstance();
    const filter = ref({
      building: "",
      unit: "",
    });
    const tableData = ref([]);
    const buildingNums = ref([]);
    const unitNums = ref([]);

    const userInfo = JSON.parse(localStorage.getItem("user_info"));

    const handleClick = (val) => {
      router.push({
        name: "history",
        params: {
          info: JSON.stringify(val.item),
        },
        query: { deviceId: val.item.deviceId, type: "household" },
      });
    };

    const getList = async () => {
      let res = await proxy.axios.get(
        `/api/base/communityUser?communityId=${userInfo[0].communityId}&buildingId=${filter.value.building}&unitId=${filter.value.unit}`
      );

      if (res.code == 200) {
        tableData.value = res.data;
        context.emit('changeLoading', false)
      } else {
        getList();
      }
    };

    const getUnitInfo = async () => {
       context.emit('changeLoading', true)
      let res = await proxy.axios.get(
        "/api/base/dictBuilding?communityId=" + userInfo[0].communityId
      );

      if (res.code == 200) {
        let tempBuild = [];
        for (let key in res.data) {
          tempBuild.push({
            text: `${key}号楼`,
            value: key,
            child: res.data[key],
          });
        }
        buildingNums.value = tempBuild;
        unitNums.value = tempBuild[0].child.map((v) => {
          return { text: `${v}单元`, value: v };
        });

        filter.value.building = tempBuild[0].value;
        filter.value.unit = tempBuild[0].child[0];

        getList();
      } else {
        getUnitInfo();
      }
    };

    const changeBuild = (e) => {
      let obj = buildingNums.value.find((v) => v.value == e);
      unitNums.value = obj.child.map((v) => {
        return { text: `${v}单元`, value: v };
      });
      filter.value.unit = unitNums.value[0].value;
      getList();
    };

    const changeRowBgColor = (item) => {
      let status = item.deviceStatus;
      return status == 0 ? '#fff9e6' : status == -1? '#ffefe6' : status == -2 ? '#f0faff' : '#fff';
    };

    const filterStatus = ({item}) => {
      let status = item.deviceStatus;
      return status == 0 ? '停用': status == 1 ? '正常' : status == -1 ? "数据异常" : status == -2 ? '设备异常' : '';
    }

    onMounted(() => {
      getUnitInfo();
    });

    return {
      handleClick,
      tableData,
      buildingNums,
      unitNums,
      changeBuild,
      filter,
      getList,
      changeRowBgColor,
      filterStatus
    };
  },
  data() {
    return {
      columns: [
        {
          title: "",
          key: "index",
          slot: true
        },
        {
          title: "房屋号",
          key: "roomId",
        },
        {
          title: "业主",
          key: "proprietorName",
        },
        // {
        //   title: "面积",
        //   key: "roomSize",
        // },
        {
          title: "入户温度",
          key: "inputTemperature",
        },
        {
          title: "回水温度",
          key: "outputTemperature",
        },
         {
          title: "状态",
          key: "deviceStatus",
          slot: true
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.household {
  padding: 0 0.1rem 0.5rem;
  .van-dropdown-menu {
    margin-bottom: 0.06rem;
  }
  .custom_title{
    display: flex;
    margin-bottom: 0.1rem;
    justify-content: center;
    div{
      display: flex;
      line-height: 0.16rem;
      margin-right: 0.1rem;
    }
    span{
      display: block;
      width: 0.14rem;
      height: 0.14rem;
      background: #fff9e6;
      margin-right: 0.06rem;
      border: 1px dashed #ffd77a;
      &.temp_high{
        background: #ffefe6;
        border: 1px dashed #ffb08f;
      }
      &.empty{
        background: #f0faff;
        border: 1px dashed #abdcff;
      }
    }
  }
}
</style>
